import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
const Header = () => {
    const [activeLink, setActiveLink] = useState(null);
    // Function to handle the click event and set the active link
    useEffect(() => {
        // Extract the URL slug from the window's location path
        const currentSlug = window.location.pathname.split('/').pop(); // Get the last part of the URL
        console.log(currentSlug);
        if (currentSlug === '') {
            setActiveLink('home');
        }
        else {
            setActiveLink(currentSlug);
        }

    }, []); // Empty array means this effect runs only on component mount
    return (
        <header>
            <div className="red-header">
                <div className="container">
                    <div className="row">
                        <div className="header-left col-lg-6 col-sm-6 col-md-6 col-xs-6">
                            <div className="top-mail-id">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/mail-icon.png`} alt="Mail Icon" />
                                <a href="mailto:sales@cbcpcb.com" className="header-text">sales@cbcpcb.com</a>
                            </div>
                        </div>
                        <div className="header-right col-lg-6 col-sm-6 col-md-6 col-xs-6">
                            <div className="toll-free-number">
                                <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/phone-icon.png`} alt="Phone Icon" />
                                <span className="header-text">CALL NOW! 669-455-1260</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-inverse menu-bar" role="navigation">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>

                        <Link className="navbar-brand" to={`${process.env.PUBLIC_URL}/`} aria-current="page">  <img src={`${process.env.PUBLIC_URL}/cbcpcb/images/logo.png`} alt="CBC Electronics Inc." title="PCB Materials &amp; Contract Manufacturing" /></Link>
                    </div>
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <div className="menu-header-container">
                            <ul id="menu-header" className="nav navbar-nav">
                                <li className={activeLink === 'home' ? 'menu-item current-menu-item' : 'menu-item'}
                                >

                                    <Link onClick={() => setActiveLink('home')} to={`${process.env.PUBLIC_URL}/`} aria-current="page">Home</Link>
                                </li>
                                <li className={activeLink === 'about-us' ? 'menu-item current-menu-item' : 'menu-item'}>
                                    <Link onClick={() => setActiveLink('about-us')} to={`${process.env.PUBLIC_URL}/about-us`}>ABOUT US</Link>

                                </li>
                                <li className={activeLink === 'services' ? 'menu-item current-menu-item' : 'menu-item'}>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/services`}
                                        onClick={() => setActiveLink('services')}
                                    >SERVICES</Link>

                                </li>
                                <li className={activeLink === 'contact-page' ? 'menu-item current-menu-item' : 'menu-item'}>
                                    <Link to={`${process.env.PUBLIC_URL}/contact-page`}
                                        onClick={() => setActiveLink('contact-page')}
                                    >CONTACT US</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}
export default Header;
