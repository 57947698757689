import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
const About = () => {
    return (
        <>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <div className="row">
                            <div className="span12">
                                <section className="title-section">
                                    {/* <h1 className="title-header">Contacts </h1> */}
                                    <ul className="breadcrumb breadcrumb__t">
                                        <li>
                                            <img
                                                src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/contact-page-home.png"
                                                alt="Home"
                                            />
                                        </li>
                                        <li className="active">About Us</li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <div id="content" className="row">
                            <div className="span12">
                                <div id="post-14" className="post-14 page type-page status-publish hentry page">
                                    <div className="row">
                                        <h1 className="inner-pageheading">About Us</h1>

                                        <div className="left-about">
                                            <div className="col-lg-9">
                                                <h2 className="inner-page-subhead">Welcome To CBC Electronics Inc</h2>

                                                <div className="about-text col-lg-8">
                                                    <p className='format_text_p'>
                                                        CBC was established in 1993 in Daly City, California. Since then it has expanded with a contract manufacturing facility in Bangalore, India, and a manufacturing partnership in Vietnam. As an experienced Electronic Contract Manufacturer, CBC Electronics has the skill and capacity to manage design engineering of custom electrical, electronic component assemblies in a wide range of fields. We have the expertise to get your design into prototype testing quickly. We manufacture customized products in the Analog, Digital & RF domains. This includes design services, system integration, PCBA, manufacturing electrical cable assemblies for automotive, transit bus industry, farm equipment, metal fabrication and full range of plastic molded parts.
                                                    </p>
                                                </div>

                                                <div className="about-image col-lg-4">
                                                    <img
                                                        src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/ic-about-page.png"
                                                        alt="About Us"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rite-about">
                                            <div className="col-xs-12 col-sm-12 col-lg-3 sidebar-offcanvas" id="sidebar">
                                                <h2 className="inner-page-subhead">Why Choose Us?</h2>

                                                <div className="bs-example">
                                                    <div className="panel-group" id="accordion">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                <h4 className="panel-title">
                                                                    <a data-parent="#accordion" data-toggle="collapse" href="#collapseOne">
                                                                        Competitiveness
                                                                        <img
                                                                            className="rite-nav-img"
                                                                            src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/modern-design-icon.png"
                                                                            alt="Competitiveness"
                                                                        />
                                                                    </a>
                                                                </h4>
                                                            </div>

                                                            <div className="panel-collapse collapse in" id="collapseOne">
                                                                <div className="panel-body">
                                                                    <p>
                                                                        With China prices creeping up, India's English-speaking talent pool, and
                                                                        government incentivized schemes, we are commended for our competitiveness.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                <h4 className="panel-title">
                                                                    <a data-parent="#accordion" data-toggle="collapse" href="#collapseTwo">
                                                                        High Quality
                                                                        <img
                                                                            className="rite-nav-img"
                                                                            src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/higgh-quality-icon.png"
                                                                            alt="High Quality"
                                                                        />
                                                                    </a>
                                                                </h4>
                                                            </div>

                                                            <div className="panel-collapse collapse" id="collapseTwo">
                                                                <div className="panel-body">
                                                                    <p>
                                                                        We have all necessary certifications for Industrial, Automotive, and Healthcare
                                                                        verticals. We have adopted Six Sigma and Kaizen methodologies.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                <h4 className="panel-title">
                                                                    <a data-parent="#accordion" data-toggle="collapse" href="#collapseThree">
                                                                        Quick Contact
                                                                        <img
                                                                            className="rite-nav-img"
                                                                            src="https://lifequoteshub.com/CBC/wp-content/themes/cbcpcb/images/quick-support-icon.png"
                                                                            alt="Quick Contact"
                                                                        />
                                                                    </a>
                                                                </h4>
                                                            </div>

                                                            <div className="panel-collapse collapse" id="collapseThree">
                                                                <div className="panel-body">
                                                                    <p>
                                                                        We have all necessary certifications for Industrial, Automotive, and Healthcare
                                                                        verticals. We have adopted Six Sigma and Kaizen methodologies.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default About;