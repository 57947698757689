import React, { useState } from 'react';
import { Link } from "react-router-dom";
const Footer = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false); // To manage the form submission state
    const [error, setError] = useState(null); // To handle errors
    const [successMessage, setSuccessMessage] = useState(''); // To show success message

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const divStyle = {
        clear: 'both'

    };
    const divStyle2 = {
        clear: 'both',
        color: '#ff0000'
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the form while submitting
        setError(null); // Clear any previous error
        setSuccessMessage(''); // Clear any previous success message

        try {
            const response = await fetch('https://www.cbcpcb.com/api/contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                setSuccessMessage('Your message has been sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                }); // Clear form data
                console.log(result);
            } else {
                const errorResponse = await response.json();
                setError(errorResponse.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            setError('Failed to send the message. Please try again.');
        } finally {
            setIsSubmitting(false); // Re-enable the form
        }
    };

    return (
        <div className="footer">
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="col-md-4 bck col-xs-6">
                                <h5>Find Us</h5>
                                <p className="home">418 8<sup>th</sup> STREET  SE – Suite-C8<br />
                                    LOVELAND CO 80537 USA</p>
                                <p className="phone"><a href="tel:+16694551260" >669-455-1260</a></p>
                                <p className="mail"><a href="mailto:sales@cbcpcb.com">sales@cbcpcb.com</a></p>
                                <ul className="social-networking">
                                    <li>
                                        <a href="/#">
                                            <img alt="#" title="#" src={`${process.env.PUBLIC_URL}/cbcpcb/images/fb.png`} className="twit" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#">
                                            <img alt="#" title="#" src={`${process.env.PUBLIC_URL}/cbcpcb/images/twit.png`} className="rss" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#">
                                            <img alt="#" title="#" src={`${process.env.PUBLIC_URL}/cbcpcb/images/g.png`} className="face" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-3 col-xs-6">
                                <h5>Quick Links</h5>
                                <div className="menu-footer-container">
                                    <ul id="menu-footer" className="menu">
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>

                                        </li>
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/about-us`}>About Us</Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/services`}>Services</Link>

                                        </li>
                                        <li className="menu-item">
                                            <Link to={`${process.env.PUBLIC_URL}/contact-page`}>Contact Us</Link>

                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-5 col-xs-12">
                                <h5>Send Us a Message</h5>
                                <div role="form" className="wpcf7" id="wpcf7-f21-o1" lang="en-US" dir="ltr">
                                    <form onSubmit={handleSubmit} className="wpcf7-form init" noValidate="novalidate" data-status="init">
                                        <div style={{ display: 'none' }}>
                                            <input type="hidden" name="_wpcf7" value="21" />
                                            <input type="hidden" name="_wpcf7_version" value="5.4.1" />
                                            <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                            <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f21-o1" />
                                            <input type="hidden" name="_wpcf7_container_post" value="0" />
                                            <input type="hidden" name="message"
                                                value={formData.message}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="text" name="name"
                                                value={formData.name}
                                                onChange={handleChange} size="40" disabled={isSubmitting} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Full Name" />
                                        </div>
                                        <div className="form-group col-md-6" style={divStyle}>
                                            <input type="email" name="email"
                                                value={formData.email}
                                                onChange={handleChange} size="40" disabled={isSubmitting} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Email ID" />
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="form-group col-md-12">
                                            <textarea name="message"
                                                value={formData.message}
                                                onChange={handleChange} disabled={isSubmitting} cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Message"></textarea>
                                        </div>

                                        <div className="subt_div">
                                            <input type="submit" disabled={isSubmitting} value="Send" className="wpcf7-form-control wpcf7-submit btn btn-primary" />
                                        </div>
                                        <div className="wpcf7-response-output" aria-hidden="true">
                                            {error && <p className="error-message" style={divStyle2}>{error}</p>}
                                            {successMessage && <p className="success-message">{successMessage}</p>}
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row copyright-txt">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                            <p>Copyright &copy; 2024 CBC Electronics Inc. All rights reserved.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
